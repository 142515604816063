import React from 'react';
import "./styles.css";

const PrivacyStatment = () => {
    return (
<div className='container-fluid'>
   <h2 style={{textAlign: "center"}}> PRIVACY POLICY</h2>
   
   <p>
   Akarava ("Akarava", "we", "us", or "our") is committed to protecting the privacy and security of your personal information. This Privacy Policy ("Policy") describes the types of information we collect from users of the Akarava mobile application ("App"), how we use and share that information, and your rights and choices with respect to your personal information.
   </p>
   <b>1.Information We Collect</b>
   <p>We collect information from you when you use our App, including:</p>
   <li>
   Personal Information: We collect personal information that you provide to us, such as your name, email address, phone number, delivery address, and payment information.
   </li>
   <li>Usage Information: We collect information about your use of the App, such as the pages you view, the products you order, and the time and date of your visits.</li>
   <li>Device Information: We collect information about your device, such as your device type, operating system, and browser type.</li>
   <li>Collection of Location Data: Our app collects and processes your location data (latitude and longitude) to enhance your shopping experience by displaying products available at nearby stores. We only collect location data when you grant permission through your device settings</li>

   <b>2.How We Use Your Information</b>
   <p>We use your information for the following purposes:</p>
   <li>To provide and improve the App: We use your information to provide and improve the App, including fulfilling your orders and processing payments.</li>
   <li>To communicate with you: We use your information to communicate with you about your orders and to send you promotional and marketing messages.</li>
    <li>To comply with legal obligations: We may use your information to comply with legal obligations, such as responding to subpoenas or other legal requests.</li>
    <li>How We Use Your Location Data: We use your location data for the following purposes:
    <ol>Finding Nearby Stores – To display stores near you and the products they offer.</ol>
    <ol>Personalized Shopping Experience – To show store-specific inventory, pricing, and promotions based on your location.</ol>
    <ol>Order Fulfillment & Delivery – To estimate delivery times and ensure accurate deliveries when placing an order.</ol>
    <ol>Location-Based Notifications & Offers – To send you relevant deals, promotions, or alerts based on nearby store availability.</ol>
</li>

<b>3.How We Share Your Information</b>
<p>We may share your information with the following third parties:</p>
<li>Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, and customer service.</li>
<li>Business Partners: We may share your information with business partners who offer products or services that may be of interest to you.</li>
<li>Law Enforcement: We may disclose your information to law enforcement or other government agencies as required by law or as necessary to protect our rights or the rights of others.</li>


<b>4.Your Rights and Choices</b>
<p>You have certain rights with respect to your personal information, including:</p>

<li>Right to Access: You have the right to request access to the personal information we hold about you.</li>
<li>Right to Correction: You have the right to request that we correct any inaccuracies in your personal information.</li>
<li>Right to Deletion: You have the right to request that we delete your personal information.</li>
<li>Right to Opt-Out: You have the right to opt-out of receiving marketing and promotional messages from us.</li>

<b>5.Data Security</b>
<p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no security system is completely foolproof, and we cannot guarantee the security of your information.</p>

<b>6.Children's Privacy</b>
<p>Our App is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under the age of 13.</p>

<b>7.Changes to This Policy</b>
<p>We may update this Policy from time to time. If we make material changes to this Policy, we will notify you by email or by posting a notice in the App.</p>

<b>8.Information Safety</b>
<p>All information is saved and stored on servers, which are secured with passwords and pins
     to ensure no unauthorised person has access to it. Once your information is in Our possession
      We adhere to strict security guidelines, protecting it against unauthorized access. 
      Company shall take reasonable steps to help protect Your rights of privacy and 
      Your information (personal or otherwise) in an effort to prevent loss, misuse, unauthorized access, 
      disclosure, alteration, or destruction of such information, in compliance with the applicable laws.
    You expressly consent to the sharing of Your information with third party service providers, including vendors,
     Merchants, insurance agents and insurers, promotion and marketing partners, consultants, research firms, business partners, 
     financial service providers, payment gateways, to process payments and manage payment card information. 
     Company does not itself store Your payment card account information, and does not have direct control over or responsibility for Your payment card account information.
      Hence, Company cannot guarantee that transmissions of Your payment card account information or personal information will always be secure or 
      that unauthorized third parties will never be able to defeat the security measures taken by Company or Company’s third-party service providers. 
      Company assumes no liability or responsibility for disclosure of Your information due to any reason, including but not limited to errors in transmission, 
      unauthorized third-party access, or other causes beyond its control. Although we shall try our best to protect Your data we cannot take any guarantee for the 
      security of Your data transmitted through the Platform. You play an important role in keeping Your personal information secure. You shall not share Your user name, password,
       or other security information for Your account with anyone.</p>

<b>9.Changes to our Privacy Policy</b>
<p>We reserve the unconditional right to change, modify, add, or remove portions of this Privacy Policy at any time, without specifically notifying You of such changes.
    Any changes or updates will be effective immediately. You should review this Privacy Policy regularly for changes. You can determine if changes have been made by checking the “Last Updated” legend above.
    If we make any significant changes we will endeavour to provide you with reasonable notice of such changes, such as via prominent notice on the Platform or to your email address on record and where required by applicable law,
    We will obtain your consent. Your acceptance of the amended Privacy Policy / Your continued use of our services after we publish or send a notice about our changes to this Policy shall signify Your 
    consent to such changes and agreement to be legally bound by the same</p>

<b>10.Sharing of Personal Information</b>
<p>We understand the importance of protecting your personal information and we do not sell, trade, or rent your personal information to third parties for their marketing purposes. We only share your personal information with our trusted service providers and partners who need the information to provide services to us or to you.<br/>
We may share your personal information with service providers who help us with tasks such as website hosting, data analysis, payment processing, customer service, and email delivery. These service providers are contractually obligated to use the personal information we provide only for the purpose of providing their services to us, and they are prohibited from disclosing or using the information for any other purpose.<br/>
We may also share your personal information if we believe it is necessary to comply with applicable laws, regulations, or legal processes, or to respond to lawful requests from public authorities, including to meet national security or law enforcement requirements.<br/>
In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring entity or third party as part of the transaction.<br/>
We take appropriate measures to ensure that any third party with whom we share your personal information provides adequate protection for your personal information in accordance with this Privacy Policy and applicable data protection laws.<br/>
</p>

<b>11. Use of Personal Information</b>
<p>We collect and use your personal information for the following purposes:

To provide you with our products or services: We use your personal information to process your orders, provide customer support, and deliver products and services to you.<br/>

To improve our offerings: We use your personal information to understand how you use our website, products, or services, and to identify areas for improvement.<br/>

To communicate with you: We use your personal information to send you newsletters, updates, promotions, and other marketing communications that may be of interest to you. You can opt-out of these communications at any time.<br/>

To comply with legal and regulatory requirements: We may use your personal information to comply with applicable laws, regulations, and legal processes.<br/>

To protect our interests: We may use your personal information to protect our interests or those of our customers, including detecting and preventing fraud, unauthorized transactions, or other illegal activities.<br/>

To personalize your experience: We may use your personal information to personalize your experience on our website or to tailor our products and services to your interests and preferences.<br/>

We will only use your personal information for the purposes for which it was collected or as otherwise authorized by you. We do not use your personal information for automated decision-making, including profiling.</p>

<b>12.Contact Us</b>
<p>If you have any questions or concerns about this Policy or our privacy practices, please contact us at akaravatech@gmail.com.</p>


    </div>
    )
}

export default PrivacyStatment;